@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');

* {
  box-sizing: border-box;
  font-weight: 275;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  font-family: Poppins;
  font-size: 1.25rem;
  font-weight: 275;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #000;
  color: #fff;
}

a {
  text-decoration: none;
  color: #fff;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #bbbbbb;
}

::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 20px;
}

@media screen and (min-width: 1100px) {
  
  ::-webkit-scrollbar {
    width: 0px;
  }
}

